<template>
    <div class="box">
    
        <!-- <div class="login_left">
            <div class="login_left_content">
                <h1>logo</h1>

                <div class="login_tese">
                    <img src="../../assets/images/login/left1.png" alt="">
                    <div>
                        <h4>全球测评平台</h4>
                        <p>无论您是商家还是企业，我们都能给您最好的服务</p>
                    </div>
                </div>

                <div class="login_tese">
                    <img src="../../assets/images/login/left2.png" alt="">
                    <div>
                        <h4>多场景覆盖</h4>
                        <p>场景覆盖广，解决您的需求问题</p>
                    </div>
                </div>

                <div class="login_tese">
                    <img src="../../assets/images/login/left3.png" alt="">
                    <div>
                        <h4>用户至上</h4>
                        <p>您的放心就是我们前进的动力</p>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="vip-main">
            <div class="login_left">
                <div class="fs40 cfff">榆阳区数字农业产业服务平台</div>
                <div class="login_left_line"></div>
                <div class="login_img"></div>
            </div>
            <div class="login_right">
                <h2 class="fs36">登录您的账户</h2>
                <!-- <div class="dlfs">
                    <a @click="choosesfs(1)" :class="choosefs == 1 ? 'choose_fs' : ''">账号登录</a>
                    <a @click="choosesfs(2)" :class="choosefs == 2 ? 'choose_fs' : ''">手机快捷登录</a>
                    <div class="choose_bottom" ref="choose_bottom"></div>
                </div> -->

                <div class="left_input" v-if="choosefs == 1">
                    <p class="fs20 mb18">账号</p>
                    <el-input style="height: 60px; margin-bottom: 36px" v-model="formData.username" placeholder="请输入账号"></el-input>

                    <p class="fs20 mb18">密码</p>
                    <el-input style="height: 60px; margin-bottom: 60px" v-model="formData.password" show-password placeholder="请输入密码"></el-input>

                    <el-button type="primary" class="submit_btn fs20" style="width: 100%; height: 61px" @click="login">登录</el-button>
                </div>

                <!-- <div class="left_input" v-else>
                    <p>手机号</p>
                    <el-input style="height: 36px; margin-bottom: 24px" placeholder="请输入手机号"></el-input>

                    <p>验证码</p>
                    <el-input style="height: 36px; margin-bottom: 24px" placeholder="请输入验证码"></el-input>

                    <el-button type="primary" style="width: 100%; height: 36px" @click="login">登录</el-button>
                </div> -->
                
            </div>
        </div>

        <!-- <img class='stone-img' src="../../assets/images/login/stone.png" alt=""> -->
  
    
    </div>
</template>

<script>

const base64 = require("js-base64").Base64;
export default {
    data(){
        return {
            choosefs: 1,
            formData: {
              username: "",
              password: ""
            }
        }
    },
    methods: {
        choosesfs(flag){
            this.choosefs = flag;
            var dom = this.$refs.choose_bottom;
            // console.log(dom)
            if(this.choosefs == 1) {
                dom.style.left = 218 + "px";
                dom.style.transition = 0.3+'s';
            } else {
                dom.style.left = 349 + "px";
                dom.style.transition = 0.3+'s';
            }
        },
        login(){
	   
	     var data = {
                username:this.formData.username ,
                password: base64.encode(this.formData.password+"12345678901234567890")+Math.floor(Math.random()*10)
            };
            this.qa.login(data).then(res => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '登录成功！',
                        type: 'success'
                    });
                    sessionStorage.setItem("Authorization",res.data.access_token);
                    sessionStorage.setItem("userData", JSON.stringify(res.data.userData));
                    this.$router.push({
                        name: 'index'
                    })
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}

</script>

<style scoped>
    .box {
        width: 100%;
        min-width: 1280px;
        height: 100%;

        position: fixed;
        background: #020B24;
    }
    .viplogin {
      width: 1920px;
      min-width: 1280px;
      height: 1080px;
      display: flex;
      background-size: 100% 100%;
    }

    .login_left_content {
        box-sizing: border-box;
        width: 838px;
        height: 100%;
        background: #F7F8FA; 
        padding-left: 337px;
        padding-top: 88px;
    }
  h1 {
      text-align: left;
      height: 40px;
      margin-bottom: 46px;
  }

  .login_tese {
      display: flex;
      margin-top: 137px;
      height: 72px;
  }

  .login_tese img {
      width: 72px;
      height: 72px;
      margin-right: 22px;
  }
  .login_tese >div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
  }
  .login_tese >div h4 {
      font-size: 18px;
      color: #1d1e1f;
  }
  .login_tese >div p {
      font-size: 14px;
      color: #8C9099;;
  }

    .stone-img {
        width: 20%;
        position: absolute;
        right: 0;
        z-index: -1;
    }
  .login_right {
      /*width: 906px;*/
      width: 459px;
      height: 569px;
      margin-right: 10%;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      margin-right: 13%;
      background: #fff;
      box-sizing: border-box;
      padding: 60px;
      border-radius: 10px;
  }
  .login_right h2 {
      font-size: 24px;
      color: #1D1E1F;
      height: 42px;
      line-height: 42px;
      
  }

  .dlfs {
      display: flex;
      margin-top: 45px;
      padding-left: 206px;
      color: #1d1e1f;
      font-size: 16px;
      position: relative;
  }
  .dlfs >a {
      margin-right: 50px;
      font-weight: 400;
      cursor: pointer;
  }
  .dlfs >a:hover {
      color: #1891FD;
      font-weight: 600 !important;
  }
  .choose_fs {
      color: #1891FD;
      font-weight: 600 !important;
  }
  .choose_bottom {
      position: absolute;
      width: 38px;
      height: 3px;
      background: #1891FD;
      border-radius: 12px;
      bottom: -12px;
      left: 218px;
  }

  .left_input {
      margin-top: 59px;
      width: 100%;
  }
  .left_input >p {
      font-size: 20px;
      color: #8C9099;
      line-height: 25px;
      font-weight: 100;
  }

  .viplogin >>> .el-input input {
      height: 36px;
  }
  
  .login_left {
    width: 590px;
    height: 569px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    margin-left: 13%;
  }
  .login_left_line {
    width: 87px;
    height: 6px;
    background: #3C7AF6;
    border-radius: 50px;
    margin-top: 5px;
  }
  .login_img {
    width: 590px;
    height: 628px;
    background: url(../../assets/images/login/bg2.png) no-repeat;
    background-size: 100% 100%;
  }
    .submit_btn {
        background: #3C7AF6;
        border-radius: 50px;
    }
    .box >>> .el-input__inner {
        height: 100%;
        font-size: 18px;
    }
</style>